export const domainSelector = state => state?.config?.domain;

export const accountSelector = state => state?.account;

export const userSelector = state => state?.account?.user;

export const customDomainSelector = state =>
  state?.company?.branding?.customDomain;

export const customMerchantDomainSelector = state =>
  state?.company?.branding?.customMerchantDomain;

export const companySelector = state => state?.company;

export const brandingSelector = state => state?.company?.branding;
