// import axios from 'src/utils/axios';
import axios from 'axios';

import { store } from 'src';
import authService from 'src/services/authService';
import ApiPaths from '../utils/ajaxUtils';
import {
  deleteRequest,
  downloadByGet,
  get,
  patch,
  post,
  put
} from '../utils/axios';
import { normalizeUser } from '../utils/normalizr/normalizrUtils';
import {
  applyPagingParamsSort,
  displayUpdateSnackbar,
  rebuildBrandingLogo,
  rebuildMerchantSettingsDto
} from '../utils/utils';
import { SET_IS_LOADING } from './userInterfaceActions';

export const LOGIN_REQUEST = '@account/login-request';

export const LOGIN_SUCCESS = '@account/login-success';

export const DATAGRID_STATE = '@account/datagrid';

export const LOGIN_FAILURE = '@account/login-failure';

export const SILENT_LOGIN = '@account/silent-login';

export const LOGOUT = '@account/logout';

export const REGISTER = '@account/register';

export const UPDATE_PROFILE = '@account/update-profile';

export const PASSWORD_CHANGED = '@account/password-changed';

export const UPDATE_PROFILE_IMAGE = '@account/update-profile-image';

export const PERSONALIZATION = '@account/personalization';

const updateUserDataOnRedux = async (dispatch, user) => {
  const data = normalizeUser(user);
  await dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      ...data
    }
  });
};

const updateUserDataGridState = async dispatch => {
  try {
    const response = await get(`${ApiPaths.datagrid.root}`);
    if (response.status == 200)
      await dispatch({
        type: DATAGRID_STATE,
        payload: response.data
      });
  } catch (error) {
    console.log(error);
  }
};

export const persistUserDataGridState = async state => {
  try {
    const response = await post(`${ApiPaths.datagrid.root}`, state);
    return response;
  } catch (error) {
    console.log(error);
  } finally {
    updateUserDataGridState(store.dispatch);
  }
};

export const setUserDataGridStateAsDefault = async id => {
  try {
    const response = await put(`${ApiPaths.datagrid.id(id)}`);
    if (response.status == 200) updateUserDataGridState(store.dispatch);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUserDataGridState = async id => {
  try {
    const response = await deleteRequest(`${ApiPaths.datagrid.id(id)}`);
    if (response.status == 200) updateUserDataGridState(store.dispatch);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const returnSimpleResponse = (
  request,
  setState,
  dispatch,
  history,
  data
) => {
  request
    .then(response => {
      if (response !== undefined) {
        if (response.data.error === false) {
          const { token } = response.data;
          if (data) {
            try {
              var new_contact = {
                'First name': data?.firstName,
                'Last name': data?.lastName,
                Email: data?.email,
                Mobile: data?.phoneNumber,
                companyName: data?.companyName,
                Industry: data?.industry,
                website: data?.website
              };
              var identifier = data?.email;
              fwcrm.identify(identifier, new_contact);
            } catch (error) {
              console.log('crm log failed: ', error);
            }
          }
          if (token) {
            const { redirectUrl, user } = response.data.data;
            authService.setSession(token);
            // eslint-disable-next-line no-use-before-define
            dispatch(setUserData(user));
            history.push(redirectUrl);
          } else {
            setState({
              message: response.data.message,
              isAlert: true,
              alertMode: 'success'
            });
            try {
              window?.dataLayer?.push({ event: 'registration' });
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          setState({
            message: response?.data?.message,
            isAlert: true,
            alertMode: 'error'
          });
        }
      }
    })
    .catch(err => {
      const errorObj = err?.response?.data;
      setState({
        message: errorObj?.message,
        isAlert: true,
        alertMode: 'error'
      });
    });
};

export const getAdminLogin = async affiliateIdentity => {
  window.open(
    `${process.env.REACT_APP_AFFILIATE_BACKEND_HOST}${
      ApiPaths.adminLogin
    }?token=${authService.getAccessToken()}&affiliateSerialOid=${affiliateIdentity}`,
    '_blank'
  );
};

export function adminLogin(token) {
  return async dispatch => {
    try {
      await dispatch({ type: LOGOUT });
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginInWithToken(token);
      await updateUserDataOnRedux(dispatch, user);
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function loginWithToken(token) {
  return async dispatch => {
    try {
      await dispatch({ type: LOGOUT });
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginInWithToken(token);
      await updateUserDataOnRedux(dispatch, user);
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function login(isAdvertiser, email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(
        isAdvertiser,
        email,
        password
      );
      updateUserDataOnRedux(dispatch, user);
      updateUserDataGridState(dispatch);
      return user;
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  const data = normalizeUser(user);
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        ...data
      }
    });
}

export function logout(userLoggedOut = false) {
  return async dispatch => {
    if (userLoggedOut) {
      await post(`${ApiPaths.userActions.logout}`);
    }
    authService.logout();

    dispatch({
      type: LOGOUT
    });

    // in case of logout reload application for loading the updating config
    window.location = '/login';
    // there isn't need for loading screen while logout
    dispatch({
      type: SET_IS_LOADING,
      payload: false
    });
  };
}

export async function registerAffiliate(data) {
  const request = await post(ApiPaths.userActions.registerAffiliate, data);
  return request;
}
export async function registerAffiliates(data) {
  const response = await post(ApiPaths.userActions.registerAffiliates, data);
  return response?.data;
}
// admin registration
export async function registerMerchantOnUserManagement(data) {
  const request = await post(
    `${ApiPaths.userActions.manager.merchants}/register-manager`,
    data
  );
  return request;
}

export async function updateAffiliateData(data) {
  await put(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/${data?.identity}`,
    data
  );
}

export async function updateMerchantData(data) {
  await put(
    `${ApiPaths.userActions.manager.merchants}/${data?.identity}`,
    data
  );
}

// normal registration
export function registerAffiliateManager(data, dispatch, history, setLoading) {
  const request = post(ApiPaths.userActions.registerMerchant, data).finally(e =>
    setLoading(false)
  );
  return setState => {
    returnSimpleResponse(request, setState, dispatch, history, data);
  };
}
export function registerLead(data, dispatch, history, setLoading) {
  const request = post(ApiPaths.userActions.registerLead, data).finally(e =>
    setLoading(false)
  );

  return setState => {
    returnSimpleResponse(request, setState, dispatch, history, data);
  };
}

export async function getMerchantUsers(paginationModel) {
  const request = await get(
    `${ApiPaths.userActions.manager.merchants}/all-merchants`,
    paginationModel
  );
  return request?.data;
}

export async function getAdvertiserUsers(paginationModel) {
  const request = await get(
    `${ApiPaths.userActions.manager.merchants}/all-advertiser-users`,
    paginationModel
  );
  return request?.data;
}

export async function getMerchantUserDetails(merchantSerialOid, role) {
  const request = await get(
    `${ApiPaths.userActions.manager.merchants}/${merchantSerialOid}`,
    { role }
  );
  return request?.data;
}

export async function getCurrentMerchantData() {
  const request = await get(
    `${ApiPaths.userActions.manager.merchants}/current-merchant`
  );
  return request?.data;
}

export function updateGeneralProfile(settings) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const user = await put(
        `${ApiPaths.userActions.settings.updateGeneralSettings}`,
        settings
      );
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          ...user
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateUserContactInfo(cn) {
  return put(
    `${ApiPaths.userActions.settings.personalization}/contact-information`,
    cn
  );
}

export function getUserContactInfo() {
  return get(
    `${ApiPaths.userActions.settings.personalization}/contact-information`
  );
}

export function updateMerchantGeneralSettings(settings) {
  settings = rebuildMerchantSettingsDto(settings);
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const user = await put(
        `${ApiPaths.userActions.settings.updateMerchantGeneralSettings}`,
        settings
      );
      store.dispatch({
        type: UPDATE_PROFILE,
        payload: {
          ...user
        }
      });
    } catch (error) {
      throw error;
    }
  };
}
export function updateMerchantTheme(theme, saveSettings) {
  put(`${ApiPaths.userActions.settings.updateMerchantTheme}`, null, {
    theme
  }).then(data => {
    store.dispatch({
      type: UPDATE_PROFILE,
      payload: {
        ...data
      }
    });
    saveSettings({ theme: theme });
  });
}

export function updatePersonalization(siteThemeOid, languageOid) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const dbPersonalization = await put(
        `${ApiPaths.userActions.settings.personalization}`,
        null,
        { siteThemeOid, languageOid }
      );
      dispatch({
        type: PERSONALIZATION,
        payload: {
          ...dbPersonalization
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export async function updatePasswordSettings(settings) {
  const user = await put(
    `${ApiPaths.userActions.settings.updatePasswordSettings}`,
    settings
  );
  return user?.data;
}

export async function getAffiliateActivationPolicy() {
  const request = await get(
    `${ApiPaths.companyActions.affiliatesNeedActivation}`
  );
  return request?.data;
}

export async function updateAffiliateNeedsActivation(needsActivation) {
  await put(`${ApiPaths.companyActions.affiliatesNeedActivation}`, null, {
    needsActivation
  });
}

export async function activateAffiliateByIdentity(affiliateIdentity) {
  await put(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/${affiliateIdentity}/activate`
  );
}

export async function deleteAffiliateByIdentity(affiliateIdentity) {
  await deleteRequest(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/${affiliateIdentity}`
  );
}

export async function hardDeleteAffiliateByIdentity(affiliateIdentity) {
  await deleteRequest(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/${affiliateIdentity}/hard`
  );
}

export async function approveAffiliateByIdentity(
  affiliateIdentity,
  isApproved,
  reason
) {
  return await put(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/${affiliateIdentity}/admin-approve?isApproved=${isApproved}&reason=${reason}`
  );
}
export async function updateDocStatus(docUuid, status, reason) {
  return put(ApiPaths.docs.updateStatus(docUuid), null, {
    status,
    reason
  });
}

export async function updateEmailSettings(settings) {
  const response = await put(
    `${ApiPaths.userActions.settings.updateEmailSettings}`,
    settings
  );
  return response?.data;
}

export async function updateUserDetails(params, setDataChanged, url) {
  const response = await post(url, null, params);

  if (response !== undefined) {
    const { message, error } = response.data;
    if (response.data.error === false) {
      setDataChanged = {
        state: true,
        message
      };
    } else {
      setDataChanged = {
        state: false,
        message
      };
    }
  }
  return setDataChanged;
}

export function uploadProfileImage(data, snackbar, dispatch, intl) {
  put(
    `${ApiPaths.userActions.settings.personalization}/pic`,
    rebuildBrandingLogo(data, 'profilePic')
  )
    .then(response => {
      if (response !== undefined) {
        const { data, status } = response;
        if (status == 200) {
          displayUpdateSnackbar(snackbar, intl, true);
          dispatch({
            type: UPDATE_PROFILE_IMAGE,
            payload: data
          });
        } else {
          displayUpdateSnackbar(snackbar, intl, false);
        }
      }
    })
    .catch(err => {
      console.warn(err);
    });
}

export function removeProfileImage(snackbar, dispatch, intl) {
  deleteRequest(`${ApiPaths.userActions.settings.personalization}/pic`)
    .then(response => {
      if (response !== undefined) {
        const { data, status } = response;
        if (status == 200) {
          displayUpdateSnackbar(snackbar, intl, true);
          dispatch({
            type: UPDATE_PROFILE_IMAGE,
            payload: data
          });
        } else {
          displayUpdateSnackbar(snackbar, intl, false);
        }
      }
    })
    .catch(err => {
      console.warn(err);
    });
}

/*
 * create download link
 * name file
 */
export async function downloadProfileImage(params) {
  const response = await downloadByGet(
    ApiPaths.userActions.downloadProfileImage,
    params
  );
  return window.URL.createObjectURL(new Blob([response.data]));
}

export function forgotPasswordAction(email, isAdvertiser) {
  const request = axios({
    method: 'get',
    url: `${ApiPaths.userActions.forgotPassword}`,
    headers: {},
    params: {
      email,
      isAdvertiser
    }
  });
  return setState => {
    returnSimpleResponse(request, setState);
  };
}

/*
 * validate user is account owner
 * name file
 */
export async function validateOwnerShip(
  snackbar,
  setShowDialog,
  showErrorMessage,
  params
) {
  const response = await post(
    ApiPaths.userActions.validateOwnerShip,
    null,
    params
  );

  if (response !== undefined) {
    const { data, error, message } = response.data;
    if (error === false) {
      if (showErrorMessage.success) {
        snackbar(message, { variant: 'success' });
      }
      setShowDialog(false);
      return true;
    }
    if (showErrorMessage.error) {
      snackbar(message, { variant: 'error' });
    }

    return false;
  }
  return false;
}

export function changeForgottenPasswordAction(password, token) {
  const request = axios({
    method: 'get',
    url: `${ApiPaths.userActions.changeForgottenPassword}`,
    headers: {},
    params: {
      password,
      token
    }
  });
  return request;
}

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data
      })
    );
  };
}

export const toggleUserBlock = async userSerialOid => {
  const response = await put(ApiPaths.blockUser(userSerialOid));
  return response?.data;
};

export const getUserCustomMlm = async userSerialOid => {
  const response = await get(ApiPaths.userCustomMlm(userSerialOid));
  return response?.data;
};
export const updateUserCustomMlm = async (userSerialOid, dto) => {
  const response = await put(ApiPaths.userCustomMlm(userSerialOid), dto);
  return response?.data;
};

export const getLifeTimeAffiliates = async (page, size, filters) => {
  let params = { page, size };
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      if (filter?.value && !filter?.field.includes('Date')) {
        const filterString = JSON.stringify(filter);

        let { field, value } = filter;
        params[`${field}`] = encodeURIComponent(filterString);
      } else {
        let { field, value } = filter;
        params[`${field}`] = value;
      }
    });
  }
  const response = await get(ApiPaths.lifetimeAffiliates, params);
  return response?.data;
};

export const addLifeTimeAffiliates = async data => {
  const response = await post(ApiPaths.lifetimeAffiliates, data);
  return response?.data;
};

export const updateLifeTimeAffiliates = async data => {
  const response = await put(ApiPaths.lifetimeAffiliates, data);
  return response?.data;
};

export const deleteLifeTimeAffiliates = async id => {
  const response = await deleteRequest(
    `${ApiPaths.lifetimeAffiliates}/${encodeURIComponent(id)}`
  );
  return response?.data;
};

export function getAllRefUsersForAffiliate(
  usid,
  page,
  size,
  filters,
  sort,
  startDate,
  endDate
) {
  let params = { page, size, startDate, endDate };

  if (sort) {
    params[`sortField`] = sort?.field;
    params[`sort`] = sort?.sort;
  }
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      const filterString = convertDataGridFilterToV6(filter);

      const { columnField } = filter;
      params[`${columnField}`] = encodeURIComponent(filterString);
    });
  }
  return axios.get(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/mlm/${usid}`,
    { params }
  );
}

export const getUserCategoriesOptions = async () => {
  const response = await get(ApiPaths.userActions.categories.options);
  return response?.data;
};

export const createUserCategory = async dto => {
  const response = await post(ApiPaths.userActions.categories.get, dto);
  return response?.data;
};

export const updateUserCategory = async dto => {
  const response = await put(ApiPaths.userActions.categories.get, dto);
  return response?.data;
};

export const toggleUserCategory = async id => {
  const response = await patch(ApiPaths.userActions.categories.toggle(id));
  return response?.data;
};

export const getUserCategoriesWithPagination = async (
  page,
  size,
  sort,
  query
) => {
  const params = { page, size, query };
  applyPagingParamsSort(sort, params);

  const response = await get(ApiPaths.userActions.categories.get, params);
  return response?.data;
};

export const getUserEmailPreferences = async affiliateSerialOid => {
  const response = await get(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/affiliate-email-preferences/${affiliateSerialOid}`
  );
  return response?.data;
};

export const updateUserEmailPreferences = async data => {
  const response = await put(
    `${ApiPaths.userActions.manager.affiliates.affiliateManagement}/affiliate-email-preferences`,
    data
  );
  console.log(response?.data);
  return response?.data;
};
