import ApiPaths from '../utils/ajaxUtils';
import {
  deleteApiCall,
  deleteRequest,
  get,
  patch,
  post,
  put
} from '../utils/axios';
import {
  applyPagingParamsSort,
  rebuildCampaignData,
  rebuildCampaignGeneralSettings
} from '../utils/utils';
import { async } from './userActions';

export const getCampaigns = async () => {
  const response = await get(ApiPaths.userActions.manager.campaigns);
  return response?.data;
};
export const getCampaignsFiltered = async (
  query,
  statuses,
  privacy,
  categories,
  paginationModel
) => {
  statuses = statuses?.toString()?.toUpperCase();
  privacy = privacy?.toString()?.toUpperCase();
  categories = categories?.toString()?.toUpperCase();
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/filtered`,
    { query, statuses, privacy, categories, ...paginationModel }
  );
  return response?.data;
};

export const getActiveCampaigns = async () => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/active`
  );
  return response?.data;
};

export const getCampaignsAsMiniList = async () => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/options`
  );
  return response?.data;
};

export const createCampaign = async data => {
  const response = await post(
    `${ApiPaths.userActions.manager.campaigns}`,
    rebuildCampaignGeneralSettings(data),
    null,
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  return response?.data;
};

export const createCloneCampaign = async serialId => {
  const formData = new FormData();
  formData.append('serialId', Number(serialId));

  const response = await post(
    `${ApiPaths.userActions.manager.campaigns}/clone/${serialId}`,
    formData,
    null,
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  return response?.data;
};

export const createCloneCampaignPayout = async payoutOid => {
  const response = await post(
    `${ApiPaths.userActions.manager.campaigns}/clone/payout/${payoutOid}`,
    null
  );
  return response?.data;
};

export const getCampaign = async oid => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/settings/${oid}`
  );
  return response?.data;
};

export const getCampaignsPerUser = async usoid => {
  const response = await get(
    `${ApiPaths.userActions.manager.default}/${usoid}/campaigns`
  );
  return response?.data;
};

export const getCampaignPayoutSettingsByCampaignSerialOid = async (
  campaignSerialOid,
  userSerialOid
) => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/general-payout-settings/${campaignSerialOid}`,
    { userSerialOid }
  );
  return response?.data;
};

export const getCampaignAffiliates = async oid => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/settings/${oid}/affiliates`
  );
  return response?.data;
};

export const deleteCampaign = async oid => {
  const response = await deleteRequest(
    `${ApiPaths.userActions.manager.campaigns}/${oid}`
  );
  return response?.data;
};

export const setDefaultCampaign = async oid => {
  const response = await put(
    `${ApiPaths.userActions.manager.campaigns}/${oid}`
  );
  return response?.data;
};

export const setAffiliateDefaultCampaign = async (
  affiliateOid,
  campaignOid
) => {
  const response = await put(
    `${ApiPaths.userActions.manager.campaigns}/${affiliateOid}/${campaignOid}`
  );
  return response?.data;
};

export const toggleSingleCampaign = async (affiliateOid, checked) => {
  const response = await put(
    `${ApiPaths.userActions.manager.campaigns}/${affiliateOid}/toggleSingleCampaign`,
    {},
    { toggledValue: checked }
  );
  return response?.data;
};

export const createCampaignPayout = async (campainOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.payout.root(campainOid),
    data
  );
  return response?.data;
};

export const getCampaignPayoutsWithPagination = async (
  campaignSerialOid,
  page,
  size,
  sort,
  query
) => {
  let params = { page, size, query };
  applyPagingParamsSort(sort, params);

  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.payout.root(
      campaignSerialOid
    ),
    params
  );
  return response?.data;
};

export const createCampaignAdvancedTracking = async (campainOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.tracking.advanced.root(
      campainOid
    ),
    data
  );
  return response?.data;
};

export const createCampaignPayoutAutomation = async (campainOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.automation.root(campainOid),
    data
  );
  return response?.data;
};

export const updateCampaignPayoutAutomation = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.automation.root(campainOid),
    data
  );
  return response?.data;
};

export const getCampaignPayoutAutomationOptions = async campainOid => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.automation.options(
      campainOid
    )
  );
  return response?.data;
};

export const updateCampaignAdvancedTracking = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.tracking.advanced.root(
      campainOid
    ),
    data
  );
  return response?.data;
};

export const updateCampaignPayout = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.payout.root(campainOid),
    data
  );
  return response?.data;
};

export const deleteCampaignPayoutSymbol = async (campainOid, data) => {
  const response = await deleteRequest(
    ApiPaths.userActions.manager.campaignsSettings.payout.symbol(campainOid),
    data
  );
  return response?.data;
};

export const deleteCampaignAdvancedTracking = async (campainOid, id) => {
  const response = await deleteRequest(
    ApiPaths.userActions.manager.campaignsSettings.tracking.advanced.delete(
      campainOid,
      id
    )
  );
  return response?.data;
};

export const updateCampaignGeneral = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.general.root(campainOid),
    rebuildCampaignGeneralSettings(data),
    null,
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  return response?.data;
};

export const updateCampaignTracking = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.tracking.root(campainOid),
    data
  );
  return response?.data;
};

export const deleteCampaignPayout = async (campainOid, payoutOid) => {
  const response = await deleteApiCall(
    ApiPaths.userActions.manager.campaignsSettings.payout.delete(
      campainOid,
      payoutOid
    )
  );
  return response?.data;
};
export const getCampaignPayoutAffiliates = async (campainOid, payoutOid) => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.payout.affiliates(
      campainOid,
      payoutOid
    )
  );
  return response?.data;
};

export const deleteCampaignPayoutAutomation = async (campainOid, payoutOid) => {
  const response = await deleteApiCall(
    ApiPaths.userActions.manager.campaignsSettings.automation.delete(
      campainOid,
      payoutOid
    )
  );
  return response?.data;
};

export const runCampaignPayoutAutomation = async (campainOid, payoutOid) => {
  const response = await patch(
    ApiPaths.userActions.manager.campaignsSettings.automation.delete(
      campainOid,
      payoutOid
    )
  );
  return response?.data;
};

// export const createCampaign = async data => {
//   const response = await post(
//     `${ajaxUtils.userActions.manager.campaigns}/settings`,
//     rebuildCampaignData(
//       data,
//       data.generalSettingsIODocument,
//       data.generalSettingsCampaignLogo
//     ),
//     null,
//     {
//       'Content-Type': 'multipart/form-data'
//     }
//   );
//   return response?.data;
// };

export const updateCampaign = async (campaignSerialOid, data) => {
  const response = await put(
    `${ApiPaths.userActions.manager.campaigns}/settings/${campaignSerialOid}/edit`,
    rebuildCampaignData(
      data,
      data.generalSettingsIODocument,
      data.generalSettingsCampaignLogo
    ),
    null,
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  return response?.data;
};

export const getCampaignTrackingData = async oid => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/${oid}/tracking-data`
  );
  return response?.data;
};

export const getAllPayoutsForCampaign = async campaignSerialOid => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/all-payout-settings/${campaignSerialOid}`
  );
  return response?.data;
};

export const getCampaignCreatives = async (
  oid,
  startDate,
  endDate,
  title,
  creativeType,
  sort,
  sortField,
  pageNumber,
  pageSize
) => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/${oid}/creatives`,
    {
      startDate,
      endDate,
      title,
      creativeType,
      sort,
      sortField,
      pageNumber,
      pageSize
    }
  );
  return response?.data;
};

export const getCampaignCreativeLinksAsMiniList = async (
  campaignSerialOid,
  affiliateSerialOid
) => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/${campaignSerialOid}/creatives/links/${affiliateSerialOid}`
  );
  return response?.data;
};

/**
 * creates new creative for campaign
 * @async
 * @param {number} oid
 * @param {object} creativeData
 * @param {File} creativeFile
 * @returns {async<boolean>}
 */
export async function addCreative(oid, creativeData, creativeFile) {
  const formData = new FormData();
  formData.append(
    'creative',
    new Blob([JSON.stringify(creativeData)], {
      type: 'application/json'
    })
  );
  formData.append('banner', creativeFile); // Assuming `creativeFile` is a File object

  const request = await post(
    `${ApiPaths.userActions.manager.campaigns}/settings/${oid}/add-creative`,
    formData,
    null,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return request?.data;
}

/**
 * creates new creatives for campaign
 * @async
 * @param {number} oid
 * @param {object} creativeData
 * @returns {async<boolean>}
 */
export async function addCreatives(oid, creativeData) {
  const request = await post(
    `${ApiPaths.userActions.manager.campaigns}/settings/${oid}/add-creatives`,
    creativeData
  );
  return request?.data;
}

export async function editCreative(oid, creativeData, creativeOid) {
  const request = await put(
    `${ApiPaths.userActions.manager.campaigns}/settings/${oid}/${creativeOid}`,
    creativeData
  );
  return request?.data;
}

export const deleteCampaignCreative = async (campaignOid, creativeOid) => {
  const response = await deleteRequest(
    `${ApiPaths.userActions.manager.campaigns}/settings/${campaignOid}/${creativeOid}`
  );
  return response?.data;
};

export const deleteAllCampaignCreative = async campaignOid => {
  const response = await deleteRequest(
    `${ApiPaths.userActions.manager.campaigns}/settings/${campaignOid}/all`
  );
  return response?.data;
};

export const deleteCampaignSocialTracking = async (campainOid, id) => {
  const response = await deleteRequest(
    ApiPaths.userActions.manager.campaignsSettings.tracking.social.delete(
      campainOid,
      id
    )
  );
  return response?.data;
};

export const updateCampaignSocialTracking = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.tracking.social.root(
      campainOid
    ),
    data
  );
  return response?.data;
};

export const createCampaignSocialTracking = async (campainOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.tracking.social.root(
      campainOid
    ),
    data
  );
  return response?.data;
};

export const addCampaignPayoutItems = async (campainOid, payoutOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.payout.payoutSttingsItem.root(
      campainOid,
      payoutOid
    ),
    data
  );
  return response?.data;
};

export const getCampaignPayoutItems = async (
  campainOid,
  payoutOid,
  query,
  paginationModel
) => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.payout.payoutSttingsItem.root(
      campainOid,
      payoutOid
    ),
    { ...paginationModel, query }
  );
  return response?.data;
};

export const editCampaignPayoutSettingsItem = async (
  campainOid,
  payoutOid,
  data
) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.payout.payoutSttingsItem.root(
      campainOid,
      payoutOid
    ),
    data
  );
  return response?.data;
};

export const deleteCampaignPayoutSettingsItem = async (
  campainOid,
  payoutOid,
  id
) => {
  const response = await deleteApiCall(
    ApiPaths.userActions.manager.campaignsSettings.payout.payoutSttingsItem.delete(
      campainOid,
      payoutOid,
      id
    )
  );
  return response?.data;
};
export const deleteAllCampaignPayoutSettingsItem = async (
  campainOid,
  payoutOid
) => {
  const response = await deleteApiCall(
    ApiPaths.userActions.manager.campaignsSettings.payout.payoutSttingsItem.delete(
      campainOid,
      payoutOid,
      'all'
    )
  );
  return response?.data;
};

export const editCampaignPayoutGrouping = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.root(
      campainOid
    ),
    data
  );
  return response?.data;
};
export const createCampaignPayoutGrouping = async (campainOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.root(
      campainOid
    ),
    data
  );
  return response?.data;
};

export const updateCampaignPayoutGrouping = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.root(
      campainOid
    ),
    data
  );
  return response?.data;
};

export const getCampaignPayoutGroupingOptions = async campainOid => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.options(
      campainOid
    )
  );
  return response?.data;
};

export const getAvailablePayoutSettingsForGrouping = async campainOid => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.available(
      campainOid
    )
  );
  return response?.data;
};
export const deletePayoutSettingsForGrouping = async (campainOid, id) => {
  const response = await deleteRequest(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.byId(
      campainOid,
      id
    )
  );
  return response?.data;
};

export const getCampaignPayoutGroupings = async (
  campainOid,
  paginationModal
) => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.payout.groupings.root(
      campainOid
    ),
    paginationModal
  );
  return response?.data;
};

export const createCampaignCaps = async (campainOid, data) => {
  const response = await post(
    ApiPaths.userActions.manager.campaignsSettings.caps.root(campainOid),
    data
  );
  return response?.data;
};

export const updateCampaignCaps = async (campainOid, data) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.caps.root(campainOid),
    data
  );
  return response?.data;
};

export const deleteCampaignCapss = async (campainOid, id) => {
  const response = await deleteRequest(
    ApiPaths.userActions.manager.campaignsSettings.caps.byId(campainOid, id)
  );
  return response?.data;
};

export const getCampaignCaps = async (campainOid, paginationModal) => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.caps.root(campainOid),
    paginationModal
  );
  return response?.data; //not in use currently if we will notice caps is used a lot replace table to use paging
};

export const getCampaignCustomMlm = async campaignSerialOid => {
  const response = await get(
    ApiPaths.userActions.manager.campaignsSettings.customMlm(campaignSerialOid)
  );
  return response?.data;
};
export const updateCampaignCustomMlm = async (campaignSerialOid, dto) => {
  const response = await put(
    ApiPaths.userActions.manager.campaignsSettings.customMlm(campaignSerialOid),
    dto
  );
  return response?.data;
};

export const getTrackingLink = async (
  affiliateId,
  campaignId,
  returnWithMacros
) => {
  const response = await get(
    `${ApiPaths.userActions.manager.campaigns}/affiliate-tracking-link`,
    { affiliateId, campaignId, returnWithMacros }
  );
  return response?.data;
};

export const getCampaignCategoriesWithPagination = async (
  page,
  size,
  sort,
  query
) => {
  const params = { page, size, query };
  applyPagingParamsSort(sort, params);

  const response = await get(
    ApiPaths.companyActions.campaigns.categories.get,
    params
  );
  return response?.data;
};

export const getCampaignCategoriesOptions = async () => {
  const response = await get(
    ApiPaths.companyActions.campaigns.categories.options
  );
  return response?.data;
};

export const createCampaignCategory = async dto => {
  const response = await post(
    ApiPaths.companyActions.campaigns.categories.get,
    dto
  );
  return response?.data;
};

export const updateCampaignCategory = async dto => {
  const response = await put(
    ApiPaths.companyActions.campaigns.categories.get,
    dto
  );
  return response?.data;
};

export const toggleCampaignCategory = async id => {
  const response = await patch(
    ApiPaths.companyActions.campaigns.categories.toggle(id)
  );
  return response?.data;
};

export const getCompanyCampaignPayoutCoupons = async (
  page,
  size,
  sort,
  query,
  campaignSerialOid
) => {
  const params = { page, size, query, campaignSerialOid };
  applyPagingParamsSort(sort, params);
  const response = await get(
    `${ApiPaths.userActions.manager.default}/coupons/`,
    params
  );
  return response?.data;
};
