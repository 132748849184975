import { store } from 'src';
import ApiPaths from '../utils/ajaxUtils';

import { get } from '../utils/axios';
import {
  COMPANY_BRANDING,
  COMPANY_CAMPAIGN_NEXT_AVAILABLE_SERIAL_OID,
  COMPANY_HAS_BRANDING,
  COMPANY_INITIAL_DATA,
  COMPANY_NOTI,
  COMPANY_REACHED_MAX_CAMPAIGNS
} from './companyActions';

export const CONFIG_INITIALIZE = '@config/config-initialize';

export const CONFIG_DOMAIN_NAME = '@config/config-domain';

export const CONFIG_IS_MOBILE_NAV_OPEN = '@config/is-mobile-nav-open';

export function getCompanyInitialData(
  getNotifications = true,
  isDomain = false
) {
  if (getNotifications) {
    getCompanyNotifications();
  }

  const request = get(
    isDomain
      ? ApiPaths.configActions.domainInitialData
      : ApiPaths.configActions.companyInitialData,
    {}
  );

  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: CONFIG_INITIALIZE,
            payload: response.data.config
          });
          dispatch({
            type: COMPANY_INITIAL_DATA,
            payload: response.data
          });
          dispatch({
            type: COMPANY_REACHED_MAX_CAMPAIGNS,
            payload: response.data.campaignLimitReached
          });
          dispatch({
            type: COMPANY_HAS_BRANDING,
            payload: response.data.companyHasBranding
          });
          dispatch({
            type: COMPANY_BRANDING,
            payload: response.data.branding
          });
          dispatch({
            type: COMPANY_CAMPAIGN_NEXT_AVAILABLE_SERIAL_OID,
            payload: response.data.campaignNextAvailableSerialOid
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export const updateDomainName = name => {
  store.dispatch({
    type: CONFIG_DOMAIN_NAME,
    payload: name
  });
};

export const getCompanyNotifications = () => {
  get(ApiPaths.configActions.companyNotifications, {})
    .then(response => {
      if (!response.data.error) {
        store.dispatch({
          type: COMPANY_NOTI,
          payload: response.data
        });
      }
    })
    .catch(err => {
      console.warn(err);
    });
};
