import jwtDecode from 'jwt-decode';
// import { instance as axios2, post } from 'src/utils/axios';
import axios from 'axios';
import { instance as axios2, post } from 'src/utils/axios';
import {
  INVALID_2FA_TOKEN,
  MISSING_2FA_TOKEN,
  NO_PERMISSION
} from 'src/utils/staticObjects';
import {
  openCustomMessage,
  publicRedirectToHome
} from '../components/GlobalErrorModal';
import ApiPaths from '../utils/ajaxUtils';
import userErrorMessageEnum from '../utils/enums/userErrorMessageEnum';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios2.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          const msg = error.response?.data?.message;
          if (
            msg == INVALID_2FA_TOKEN ||
            msg == MISSING_2FA_TOKEN ||
            msg == NO_PERMISSION
          ) {
            throw error;
          }
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
    axios2.interceptors.request.use(
      config => {
        const csrfToken = getCsrfTokenFromCookieOrHeader(); // Implement this function to fetch the CSRF token
        if (csrfToken) {
          config.headers['X-XSRF-TOKEN'] = csrfToken;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      // if user logged in and then close browser for too long there  isnt any logic showing him error message
      const timeGap = Math.abs((currentTime - decoded.exp) / 1000);
      if (decoded.exp > currentTime && timeGap > 30 && timeGap < 60) {
        openCustomMessage({
          message: userErrorMessageEnum.jwtExpired,
          onClose: publicRedirectToHome
        });
      }
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = async (isAdvertiser, email, password) => {
    this.setSession(null);
    const response = await axios({
      method: 'post',
      url: `${
        isAdvertiser
          ? ApiPaths.userActions.login.adv
          : ApiPaths.userActions.login.root
      }`,
      headers: {
        Authorization: ''
      },
      data: {
        email,
        password
      }
    });
    const { token, data: user } = response.data;
    this.setSession(token);
    this.setTimoutRefreshToken(token);
    return user;
  };

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      const request = post(ApiPaths.userActions.loginWithToken, null, null);

      request
        .then(response => {
          const { data: user } = response;
          if (user) {
            this.setTimoutRefreshToken(this.getAccessToken());
            resolve(user);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = async accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('persist:root');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();

  refreshToken = async () => {
    try {
      const {
        data: { token }
      } = await post(`${ApiPaths.userActions.refreshToken}`);
      this.setSession(token);
      this.setTimoutRefreshToken(token);
      return token;
    } catch (e) {
      console.warn(e);
      return this.getAccessToken();
    }
  };

  setTimoutRefreshToken = accessToken => {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    let remainingTimeForExpiration = decoded.exp - currentTime;
    // refresh token 1 minute before expiration
    if (remainingTimeForExpiration - 60 < 0) {
      remainingTimeForExpiration = 120;
    }
    setTimeout(this.refreshToken, (remainingTimeForExpiration - 60) * 1000);
  };
}

const authService = new AuthService();

// Implement logic to get the CSRF token from cookie or header
function getCsrfTokenFromCookieOrHeader() {
  console.log(
    document.cookie.split(';').find(c => c.trim().startsWith('XSRF-TOKEN='))
  );
  return document.cookie
    .split(';')
    .find(c => c.trim().startsWith('XSRF-TOKEN='));
}

export default authService;
