import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import { companySelector } from 'src/selectors/selectors';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';
import { getUtcDate } from 'src/utils/utils';

export default function MembershipBanner(props) {
  const { membership } = useSelector(companySelector);
  const intl = useIntl();
  const history = useHistory();

  return (
    membership?.type == 'FREE' && (
      <>
        <ScopeGuard scope={UserPermissionsEnum.BILLING.value}>
          <Alert
            icon={false}
            severity="info"
            // sx={{ backgroundColor: '#FFEFED' }}
            action={
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <Button
                  onClick={() => history.push('/app/management/billing')}
                  color="inherit"
                  size="small"
                >
                  {intl.formatMessage({ id: 'upgrade' })}
                </Button>
                <Button
                  onClick={() =>
                    window.open(
                      'https://www.tracknow.io/book-a-demo.html',
                      '_blank'
                    )
                  }
                  color="inherit"
                  size="small"
                  variant="contained"
                >
                  {intl.formatMessage({ id: 'Book a Demo' })}
                </Button>
              </Box>
            }
          >
            <AlertTitle>Book a Demo with Tracknow Expert</AlertTitle>

            {intl.formatMessage({ id: 'free_trial_ending' }, [
              getUtcDate(membership?.expirationDate).fromNow()
            ])}
          </Alert>
        </ScopeGuard>
      </>
    )
  );
}
