import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase'
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08)
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08)
  },
  green: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08)
  },
  yellow: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08)
  },
  red: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08)
  },
  grey: {
    color: '#808080',
    backgroundColor: alpha('#808080', 0.08)
  }
}));

function ColoredLabel({
  className,
  color,
  children,
  style,
  isPointer = false,
  ...rest
}) {
  const classes = useStyles();

  return (
    <span
      style={isPointer ? { cursor: 'pointer' } : {}}
      className={clsx(
        classes.root,
        {
          [classes[color]]: color
        },
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
}

ColoredLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'green', 'yellow', 'red'])
};

ColoredLabel.defaultProps = {
  className: '',
  color: 'secondary'
};

export default ColoredLabel;
